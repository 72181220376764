import {IS_FETCHED} from '../Constants'

const initialState = false

function isFetchedReducer(state=initialState, action){
    switch(action.type){
        case IS_FETCHED:
            return action.payload
        default:
            return state
    }
}

export default isFetchedReducer