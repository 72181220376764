import {FETCH_ABOUT} from '../Constants'

const initialState = {}

function aboutReducer(state=initialState, action){
    switch(action.type){
        case FETCH_ABOUT:
            return action.payload
        default:
            return state
    }
}

export default aboutReducer