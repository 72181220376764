import { FETCH_MODULES, FETCH_MODULE_BY_ID, RESET_MODULE } from '../Constants'

const initialState = []

function moduleIdReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MODULE_BY_ID:
            return action.payload
        case RESET_MODULE:
            return action.payload
        default:
            return state
    }
}

export default moduleIdReducer