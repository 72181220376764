import {LIMITED_ARTICLE_FETCH,LIMITED_ARTICLE_ADD_FETCH,RESET_LIMITED_ARTICLES} from '../Constants'
import {Console} from '../../GeneralJS/Console'
const initialState = []

function limitedArticleReducer(state=initialState, action){
    // Console(action)
    switch(action.type){
        case LIMITED_ARTICLE_FETCH:
            return action.payload
        case RESET_LIMITED_ARTICLES:
            return action.payload
        case LIMITED_ARTICLE_ADD_FETCH:
            const array = state.concat(action.payload)
            const lastItem = array[array.length -1 ]
            const value = lastItem.createdAt
            Console(array.length)
            return array
        default:
            return state
    }
}

export default limitedArticleReducer