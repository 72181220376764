import {PROVIDER_SIGNUP} from '../Constants'

const initialState = false

function providerSignUpReducer(state=initialState, action){
    switch(action.type){
        case PROVIDER_SIGNUP:
            return action.payload
        default:
            return state
    }
}

export default providerSignUpReducer