import {SET_BLOCKED_USER} from '../Constants'

const initialState = false

function blockedUserReducer(state=initialState, action){
    switch(action.type){
        case SET_BLOCKED_USER:
            return action.payload
        default:
            return state
    }
}

export default blockedUserReducer