import { FETCH_TOPICS, FETCH_TOPIC_BY_ID, RESET_TOPIC } from '../Constants'

const initialState = []

function topicReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TOPICS:
            return action.payload
        case FETCH_TOPIC_BY_ID:
            return action.payload
        case RESET_TOPIC:
            return action.payload
        default:
            return state
    }
}

export default topicReducer