import { USER_DETAILS, USER_UPDATE, USER_TOKEN_PATCH } from '../Constants'

const initialState = { roles: [] }

function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_DETAILS:
            return action.payload
        case USER_UPDATE:
            return action.payload
        case USER_TOKEN_PATCH:
            const updatedTokens = Array.from(new Set(state?.notificationToken || []).add(action.payload))
            return {
                ...state,
                notificationToken: updatedTokens
            }
        default:
            return state
    }
}

export default userReducer