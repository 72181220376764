import HelveticaNeue from '../../assets/fonts/helveticaneue.woff2'

export const helvetica = {
    fontFamily: 'HelveticaNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('HelveticaNeue'),
      url(${HelveticaNeue}) format('woff2')
    `,
};

