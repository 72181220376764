import { FETCH_MODULES_FAQ } from '../Constants'

const initialState = []

function faqModuleReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MODULES_FAQ:
            return action.payload
        default:
            return state
    }
}

export default faqModuleReducer