import { FETCH_ARTICLES, FETCH_ARTICLES_BY_MODULE, FETCH_ARTICLES_BY_TOPICS, FETCH_ARTICLE_BY_ID, RESET_ARTICLE } from '../Constants'

const initialState = []

function topicReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ARTICLES_BY_MODULE:
            return action.payload
        case FETCH_ARTICLES_BY_TOPICS:
            return action.payload
        case FETCH_ARTICLE_BY_ID:
            return action.payload
        case RESET_ARTICLE:
            return action.payload
        default:
            return state
    }
}

export default topicReducer