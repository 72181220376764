import {FETCH_QUESTIONS} from '../Constants'

const initialState = []

function questionReducer(state=initialState, action){
    switch(action.type){
        case FETCH_QUESTIONS:
            return action.payload
        default:
            return state
    }
}

export default questionReducer