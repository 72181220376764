import {FETCH_ARTICLES_FAQ,FETCH_ARTICLES_FAQ_ADDED,RESET_FAQ_ARTICLES} from '../Constants'
import {Console} from '../../GeneralJS/Console'
const initialState = []

function faqArticleReducer(state=initialState, action){
    Console(action)
    switch(action.type){
        case FETCH_ARTICLES_FAQ:
            return action.payload
        case RESET_FAQ_ARTICLES:
            return action.payload
        case FETCH_ARTICLES_FAQ_ADDED:
            const array = state.concat(action.payload)
            const lastItem = array[array.length -1 ]
            const value = lastItem.createdAt
            Console(array.length)
            return array
        default:
            return state
    }
}

export default faqArticleReducer