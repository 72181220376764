import { FETCH_COMMENTS, RESET_COMMENTS } from '../Constants'

const initialState = []

function commentReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_COMMENTS:
            return action.payload
        case RESET_COMMENTS:
            return action.payload
        default:
            return state
    }
}

export default commentReducer