import {FETCH_HELPLINE} from '../Constants'

const initialState = []

function helplineReducer(state=initialState, action){
    switch(action.type){
        case FETCH_HELPLINE:
            return action.payload
        default:
            return state
    }
}

export default helplineReducer