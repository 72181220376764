import {USER_ROLE} from '../Constants'

const initialState = ''

function userRoleReducer(state=initialState, action){
    switch(action.type){
        case USER_ROLE:
            return action.payload
        default:
            return state
    }
}

export default userRoleReducer