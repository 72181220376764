import {FETCH_ADMIN_DATA} from '../Constants'

const initialState = [{whatsapp:'',number:'',countryCode:'+91'},{email:''}]

function adminDataReducer(state=initialState, action){
    switch(action.type){
        case FETCH_ADMIN_DATA:
            return action.payload
        default:
            return state
    }
}

export default adminDataReducer