import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import {helvetica} from './Font'

let Theme = createMuiTheme({
    palette: {
        primary: {
            light: '#e2e6f4',
            main: '#0f1c59',
            dark: '#000030'
        },
        secondary: {
            light: '#5ee5d7',
            main:'#12b2a6',
            dark: '#008277'
        }
    },
    typography: {
        fontFamily: 'HelveticaNeue'
    },
    overrides: {
        MuiCssBaseline: {
            '@global' : {
                '@font-face': [helvetica]
            }
        }
    }
})

Theme = responsiveFontSizes(Theme)

export default Theme
