import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
//material ui imports
import { ThemeProvider } from '@material-ui/core'
import Theme from './components/materialUI/Theme'
import { CssBaseline } from '@material-ui/core'
import { saveErrorToServer } from './components/firebase/utility'
import useIsLoginView from './hooks/useIsLoginView';
const MainApp = lazy(() => import('./components/MainApp'))
const DesktopMain = lazy(() => import('./components/WebVersion/MainApp'))



function RouteApp() {
  const isLoginView = useIsLoginView()
  return <div style={{ backgroundColor: 'white' }}>
    {
      isLoginView ? <MainApp /> :
        <DesktopMain />
    }
  </div>
}

function App(props) {
  useEffect(() => {
    window.onerror = async function (msg, url, lineNo, colNo, error) {
      try {
        await saveErrorToServer({ msg, stack: error.stack ? error.stack : '' })
        window.localStorage.removeItem('userId')
      } catch (err) {
        console.log('Error while updating to firestore')
      }
      return true
    }
  }, [])

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Suspense fallback={null}>
        <Router>
          <RouteApp />
        </Router>
      </Suspense>
    </ThemeProvider >
  );
}

export default App;
