import firebase from './config'

export const saveErrorToServer = async ({ msg = '', stack = '' }) => {
    const collectionRef = firebase.firestore().collection('user-exceptions')
    const currentUser = firebase.auth().currentUser
    const userToken = (!!currentUser) ? await currentUser.getIdToken() : ''
    const userId = window.localStorage.getItem('userId')
    const payload = {
        errorMessage: msg,
        errorStackTrace: stack,
        ['client-info']: {
            userAgent: (navigator && navigator.userAgent) ? navigator.userAgent : '',
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            devicePixelRatio: window.devicePixelRatio,
            ['app_version']: process.env.REACT_APP_VERSION,
        },
        userId: userId !== null ? userId : '',
        userToken: userToken,
        createdAt: firebase.firestore.Timestamp.now()
    }
    const docId = collectionRef.doc().id
    await collectionRef.doc(docId).set(payload)
}