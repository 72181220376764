import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/messaging'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSENGER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

const firebaseApp = firebase.initializeApp(config)
// const messaging = firebase.messaging.isSupported() ? console.log("run here") : null
firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
})
firebase.firestore().enablePersistence()
// export {messaging}
if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging()
    messaging.onMessage(payload => {
        console.log(payload)
        const notificationTitle = payload.notification.title
        const clickAction = payload.notification && payload.notification.click_action ? payload.notification.click_action : ''
        const url = clickAction === '' ? `${window.location.origin}/home` : `${window.location.origin}${clickAction}`
        const notificationOptions = {
            body: payload.notification.body
        }
        if (!('Notification' in window)) {
            console.log("This browser does not support system notifications");
        } else if (Notification.permission === 'granted') {
            const notification = new Notification(notificationTitle, notificationOptions)
            notification.onclick = function (event) {
                event.preventDefault()
                window.open(url, '_self')
                notification.close()
            }
        }
    })
}
export default firebase