import { FETCH_AUTHORS, RESET_AUTHORS } from '../Constants'

const initialState = []

function authorReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_AUTHORS:
            return action.payload
        case RESET_AUTHORS:
            return action.payload
        default:
            return state
    }
}

export default authorReducer