import {FETCH_NOTIFICATIONS} from '../Constants'

const initialState = []

function notificationReducer(state=initialState, action){
    switch(action.type){
        case FETCH_NOTIFICATIONS:
            return action.payload
        default:
            return state
    }
}

export default notificationReducer