import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import SwUpdate from './SwUpdate'
import * as serviceWorker from './serviceWorker';
import './i18n'
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk'
import { Provider } from 'react-redux';
import allReducers from './components/store/reducers/allReducers';

const store = createStore(allReducers, applyMiddleware(reduxThunk))

// ReactDOM.render(
//   // <React.StrictMode>
//   <Provider store={store}>
//     <App />
//   </Provider>
//   // </React.StrictMode>
//   ,
//   document.getElementById('root')
// );

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <Suspense fallback={null}>
        <SwUpdate />
      </Suspense>
    </Provider>,
    rootElement
  )
} else {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={null}>
        <SwUpdate />
      </Suspense>
    </Provider>,
    rootElement
  )
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
