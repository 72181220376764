import {FECTH_UID} from '../Constants'

const initialState = ''

function uidReducer(state=initialState, action){
    switch(action.type){
        case FECTH_UID:
            return action.payload
        default:
            return state
    }
}

export default uidReducer