import { FETCH_ARTICLES, FETCH_ARTICLES_BY_MODULE, FETCH_ARTICLES_BY_TOPICS, FETCH_ARTICLE_BY_ID, RESET_ARTICLE } from '../Constants'

const initialState = []

function allArticleReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ARTICLES:
            return action.payload
        default:
            return state
    }
}

export default allArticleReducer