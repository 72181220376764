import {SET_NO_USER} from '../Constants'

const initialState = false

function noUserReducer(state=initialState, action){
    switch(action.type){
        case SET_NO_USER:
            return action.payload
        default:
            return state
    }
}

export default noUserReducer