import { FETCH_MODULES, FETCH_MODULE_BY_ID } from '../Constants'

const initialState = []

function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MODULES:
            return action.payload
        default:
            return state
    }
}

export default moduleReducer