import {LANGUAGE_CHANGE} from '../Constants'

const initialState = window.localStorage.getItem('lngCode') === null ? 'kn' : window.localStorage.getItem('lngCode')

function moduleReducer(state=initialState, action){
    switch(action.type){
        case LANGUAGE_CHANGE:
            return action.payload
        default:
            return state
    }
}

export default moduleReducer