import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {Console} from './components/GeneralJS/Console'
import Backend from 'i18next-xhr-backend';

 
i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
 
  .init({
    debug: process.env.NODE_ENV === 'development',
 
    lng: window.localStorage.getItem('lngCode') === null ? 'kn': window.localStorage.getItem('lngCode'),
    fallbackLng: window.localStorage.getItem('lngCode') === null ? 'kn': window.localStorage.getItem('lngCode'),
    whitelist: ['en','kn','hi'],
 
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        // loadPath: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_LOCALES_PROJECT}/o/locales%2F{{lng}}%2F{{ns}}.json?alt=media`,
        // crossDomain:true
      },
  });

  i18n.loadNamespaces(['home','search','qa','settings','language','modules']).then(() => {Console('namespaced loaded')})

 
 
export default i18n;